<template>
    <div>
            <b-row>
                <b-col cols="12">
                    <b-form-group :label="$t('name')">
                        <b-input-group >
                            <div class="label-as-input">{{ isNotNullValue(form.name) }}</div>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group :label="$t('code')">
                        <b-input-group>
                            <div class="label-as-input">{{ isNotNullValue(form.code) }}</div>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group :label="$t('rank')">
                        <b-input-group>
                            <div class="label-as-input">{{ isNotNullValue(form.rank) }}</div>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
    </div>
</template>

<script>
    //Service
    import CountryService from "@/services/CountryService";

    export default {
        data() {
            return {
                formLoading: false,
                form: {
                    code: null,
                    name: null,
                    rank: null
                }
            }
        },
        props: {
            formId: {
                type: Number,
            }
        },
        async created() {
            await this.get(this.formId)
        },
        methods: {
            get(id) {
                CountryService.get(id)
                              .then((response) => {
                                  this.form = response.data.data;
                              })
                              .catch((error) => {
                                  this.showErrors(error)
                              })
            }
        }
    }
</script>
